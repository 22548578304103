<template><slot name="inner" /></template>

<script setup>
    import { useEventBus } from '@vueuse/core';
    import { LinearFilter, LinearMipmapLinearFilter, LinearSRGBColorSpace, Vector3 } from 'three';
    import { computed, onMounted, reactive, shallowRef, watch } from 'vue';

    import { LoaderEvent } from '@resn/gozer-loading';
    import { randomInt } from '@resn/gozer-math';
    import { usePane, useRafBool, useThreeObject } from '@resn/gozer-vue';
    import { useLoader } from '@resn/gozer-vue/loading';
    import { gsap } from '@resn/gsap';

    import PackMaterial from '../materials/PackMaterial';
    import { getAllMeshes } from '../utils';
    import { getTextures } from './data';
    import { loadBitmapTexture } from '~/libs/three/loadBitmapTexture';
    import { useGlobalAssets } from '~/providers/GlobalAssets';

    const PALETTE_PACK_DISSOLVE = ['#c5b3d2', '#b676cf', '#0a44df'];

    const props = defineProps({
        animations: { type: Array },
        active: { type: Boolean, default: true },
        image: { default: null },
        color: { default: '#000000' },

        hasPane: { type: Boolean, default: true },
        hasAmbientMotion: { type: Boolean, default: true },
        hasCompressedTextures: { type: Boolean, default: true },
        hasAutoLoad: { type: Boolean, default: true },

        addToParent: { type: Boolean, default: false },

        scaleObject: { type: Number, default: 1 },
    });
    const propsReactive = reactive({
        active: false,

        vShow: true,
        vOpen: false,

        mcRotate: 0,
        mcScale: 6,
        mcRotateWorld: 0,

        color: props.color,
        colorDissolve:
            PALETTE_PACK_DISSOLVE[randomInt(0, PALETTE_PACK_DISSOLVE.length)] || '#ff00ff',
        dissolveOffset: { x: 0, y: 0 },

        speed: 0.8,
        motionAmb: 0,
        dissolve: 0,
        bumpScale: 3,
    });

    const vPosStart = new Vector3();
    const vPosAmbient = new Vector3();

    const vRotStart = new Vector3();
    const vRotAmbient = new Vector3();

    const visible = computed(() => propsReactive.vShow && !propsReactive.vOpen);
    const active = computed(() => props.active && propsReactive.active);

    const { object } = useThreeObject(null, {
        name: 'Pack',
        addToParent: props.addToParent,
        props: { v: visible, s: props.scaleObject },
    });
    const refObject = shallowRef();

    const bus = useEventBus('pack');
    const globalAssets = useGlobalAssets();

    onMounted(() => {
        setRGBImage(props.image);
    });

    const init = ({ object: objectRef, textures = null } = {}) => {
        refObject.value = objectRef;

        disposeMaterial(objectRef);

        if (objectRef.parent) objectRef.parent.add(object);
        else {
            vPosStart.copy(objectRef.position);
            vRotStart.copy(objectRef.rotation);
        }
        object.add(objectRef);

        if (globalAssets.assets) updateMaterial();
        else globalAssets.loader.once(LoaderEvent.LOAD_COMPLETE, updateMaterial);

        if (textures) updateTextures(textures);

        loader?.start();
    };

    const shader = new PackMaterial();

    const disposeMaterial = (object) => {
        getAllMeshes(object).forEach((node) => node.material.dispose());
    };

    let readyCount = 0;
    let readyTriggered = false;
    const checkReady = () => {
        if (readyCount === 3 && !readyTriggered) {
            bus.emit('ready'); // Emit "ready" event once both textures and RGB are loaded
            bus.reset();
            readyTriggered = true;
        }
    };

    const updateMaterial = () => {
        getAllMeshes(refObject.value).forEach((node) => {
            node.material = shader;
        });
        updateTextures({});
    };

    const updateTextures = ({
        matcapPack,
        matcapPack1,
        matcapPack0,
        mapBumpPack,
        mapEdgesPack,
    }) => {
        const { assets } = globalAssets;

        if (assets) {
            shader.tMatcapIrri = assets.matcapRevised6;
            shader.tNoiseDissolve = assets.pNoise;
        }

        if (matcapPack) shader.tMatcap = matcapPack;
        if (matcapPack0) shader.tMatcap0 = matcapPack0;
        if (matcapPack1) shader.tMatcap1 = matcapPack1;
        if (mapBumpPack) {
            shader.bumpMap = mapBumpPack;
            shader.bumpMap.channel = 1;
        }
        if (mapEdgesPack) shader.tEdges = mapEdgesPack;

        shader.defines.USE_MATCAP_BASE = shader.tMatcap !== null;
        shader.defines.USE_MATCAP_IRRI = shader.tMatcapIrri !== null;
        shader.defines.USE_MATCAP_0 = shader.tMatcap0 !== null;
        shader.defines.USE_MATCAP_1 = shader.tMatcap1 !== null;

        readyCount++;
        checkReady();
    };

    const setUniforms = (props) => {
        const { color, colorDissolve } = props;
        if (shader) {
            shader.u_color.set(color);
            shader.u_color_dissolve.set(colorDissolve);
            shader.u_dissolve = props.dissolve;

            shader.u_matcapRotationWorld = props.mcRotateWorld;
            shader.u_matcapScale = props.mcScale;

            shader.bumpScale = props.bumpScale;

            shader.defines.USE_COLOR = shader.u_color.getHex() !== 0x000000;
            shader.needsUpdate = true;
        }
    };
    watch(propsReactive, setUniforms, { immediate: true });

    const setRGBImage = (url) => {
        if (!url) {
            console.warn('No image URL provided for RGB texture');
            return;
        }
        loadBitmapTexture(url, { flipY: true }).then((texture) => {
            shader.tRGB?.dispose();
            shader.tRGB = texture;

            readyCount++;
            checkReady();
        });
    };
    watch(() => props.image, setRGBImage);

    const setColor = (color) => (propsReactive.color = color);
    watch(() => props.color, setColor);

    useRafBool(active, ({ timestamp, delta }) => {
        const ts = timestamp / 1000;
        const { speed, motionAmb } = propsReactive;

        vPosAmbient.set(0, Math.sin(ts * speed * 0.2), 0).multiplyScalar(0.1 * motionAmb);
        vRotAmbient
            .set(
                Math.sin(ts * speed * 0.4) * -0.5,
                Math.sin(ts * speed * 0.3) * 0.1,
                Math.cos(ts * speed * 0.6) * 0.2
            )
            .multiplyScalar(0.25 * motionAmb);

        const vRot = vRotStart.clone().add(vRotAmbient);
        const vPos = vPosStart.clone().add(vPosAmbient);

        object.position.copy(vPos);
        object.rotation.setFromVector3(vRot);

        shader.u_matcapRotation += (delta / 1000) * propsReactive.speed;
    });

    let tlShow, tlOpen;
    const show = ({ delay = 0, useFog = false } = {}) => {
        tlShow?.kill();
        tlShow = gsap
            .timeline({
                delay,
                onStart: () => {
                    propsReactive.vShow = true;
                    propsReactive.active = true;
                },
            })
            .to(
                propsReactive,
                {
                    motionAmb: Math.max(+props.hasAmbientMotion, 0.001),
                    duration: 2,
                    ease: 'sine.out',
                },
                0
            );

        shader.defines.USE_FOG = useFog;
        return tlShow;
    };

    const open = ({ delay = 0 } = {}) => {
        tlOpen?.kill();
        tlOpen = gsap
            .timeline({
                delay,
                onStart: () => {
                    const rndColor = PALETTE_PACK_DISSOLVE[randomInt(0, 2)];
                    propsReactive.colorDissolve = rndColor;
                },
                onComplete: () => (propsReactive.vOpen = true),
            })
            .to(propsReactive, { motionAmb: 0, duration: 1.5, ease: 'sine.out' }, 0)
            .to(propsReactive, { dissolve: 1, duration: 1.5, ease: 'power1.inOut' }, 0)
            .set(propsReactive, { motionAmb: 0 });
        return tlOpen;
    };

    const reset = () => {
        propsReactive.vShow = true;
        propsReactive.vOpen = false;
        propsReactive.active = false;

        propsReactive.motionAmb = 0;
        propsReactive.dissolve = 0;

        vRotAmbient.set(0, 0, 0);
        vPosAmbient.set(0, 0, 0);

        shader.u_matcapRotation = 0;
    };

    let loader;
    const { hasAutoLoad, hasCompressedTextures } = props;
    if (hasAutoLoad) {
        loader = useLoader(getTextures({ compressed: hasCompressedTextures })).once(
            LoaderEvent.LOAD_COMPLETE,
            ({ data }) => {
                const noFlipYMap = ['mapBumpPack', 'mapEdgesPack'];

                for (const key in data) {
                    const map = data[key];
                    map.name = key;
                    map.colorSpace = LinearSRGBColorSpace;
                    map.flipY = noFlipYMap.includes(key) ? false : true;
                    if (key === 'mapBumpPack' && hasCompressedTextures) {
                        map.generateMipmaps = true;
                        map.minFilter = LinearMipmapLinearFilter;
                        map.magFilter = LinearFilter;
                    }
                    map.needsUpdate = true;
                }
                updateTextures(data);
            }
        );
    }

    defineExpose({
        init,
        show,
        open,
        reset,
        object,
        shader,
        exposedVectors: { vRotStart, vPosStart },
    });

    if (props.hasPane)
        usePane(
            [
                {
                    value: propsReactive,
                    options: {
                        onChange: (e) => {
                            if (e.target.key == 'mcRotate') shader.u_matcapRotation = e.value;
                            if (e.target.key == 'mcScale') shader.u_matcapScale = e.value;
                        },
                    },
                },
            ],
            {
                title: 'Pack',
                expanded: false,
            }
        );
</script>
