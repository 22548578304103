export const getTextures = ({ compressed = true } = {}) => ({
    matcapPack: '/textures/pack/matcap-pack0-cycles@lg.webp#texture',
    matcapPack0: '/textures/box/map-box-matcap-rough@lg.webp#texture',
    matcapPack1: '/textures/pack/matcap-pack0-reflection@lg.webp#texture',
    mapBumpPack: '/textures/pack/map-bump-pack.png#texture',
    mapEdgesPack: '/textures/pack/map-edges-pack.png#texture',
    ...(compressed
        ? {
              matcapPack: '/textures/pack/matcap-pack0-cycles-updated-etc1s.ktx2',
              matcapPack0: '/textures/box/map-box-matcap-rough-updated-etc1s.ktx2',
              matcapPack1: '/textures/pack/matcap-pack0-reflection-etc1s.ktx2',
          }
        : {}),
});
